<div class="text-field" [ngClass]="state()">
  <label class="text-field-container">
    <ui-inline-svg *ngIf="icon() && iconPosition() !== 'right'" class="text-field-icon" [name]="icon()"></ui-inline-svg>
    <input class="text-field-input" [mask]="mask()" type="text" [placeholder]="placeholder()" [(ngModel)]="value" [name]="name()" [disabled]="disabled()">
    <ui-inline-svg *ngIf="icon() && iconPosition() === 'right'" class="text-field-icon-action" [name]="icon()"></ui-inline-svg>
  </label>
  @if (alert() && state() !== fieldState.Default) {
    <div class="text-field-alert">
      <ui-inline-svg class="text-field-alert-icon" [name]="alertIcon()"></ui-inline-svg>
      <span class="text-field-alert-text">{{ alert() }}</span>
    </div>
  }
</div>
