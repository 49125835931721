import { CommonModule } from '@angular/common';
import {
  Component, OnInit, computed, input, model, signal,
} from '@angular/core';

import { DropdownOption, DropdownValue } from '../../models/field.model';
import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-dropdown',
  standalone: true,
  imports: [ CommonModule, InlineSvgComponent ],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
})
export class DropdownComponent implements OnInit {
  name = input<string>(`ui-dropdown-${(Math.round((Math.random() * 100000000)).toString(16))}`);
  options = input.required<DropdownOption[]>();
  dropdownLabel = input<string>();
  isOpen = signal(false);
  value = model<DropdownValue>();

  displayText = computed(() =>
    this.options().find(v => v.value === this.value())?.label || ''
  );


  ngOnInit() {
    if (this.value() === undefined && this.options().length > 0) {
      this.value.set(this.options()[0].value);
    }
  }

  onOpen() {
    this.isOpen.set(!this.isOpen());
  }

  onSelect(item: DropdownOption): void {
    this.value.set(item.value);
    this.isOpen.set(false);
  }
}
