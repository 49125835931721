<div class="tabs">
  @for (tab of tabs(); track tab) {
    <button type="button" [class.is-active]="tab.select" class="tabs-item" (click)="selectTab()">
      @if (icon()) {
        <ui-inline-svg (click)="checkTab()" [name]="'radio-true'"></ui-inline-svg>
      }
      <span class="tabs-item-text">tab-item 1</span>
    </button>
  }
</div>
