import { CommonModule } from '@angular/common';
import {
  Component, input, model, signal,
} from '@angular/core';

import { DropdownOption, DropdownValue } from '../../models/field.model';
import { InlineSvgComponent } from '../inline-svg/inline-svg.component';

@Component({
  selector: 'ui-select',
  standalone: true,
  imports: [ CommonModule, InlineSvgComponent ],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
})
export class SelectComponent {
  options = input.required<DropdownOption[]>();
  value = model<DropdownValue>();
  placeholder = input<string>('');
  isOpen = signal(false);
  selectedOption = signal<DropdownValue>('');
  disabled = input<boolean>(false);

  onOpen(): void {
    this.isOpen.set(!this.isOpen());
  }

  onSelect(option: DropdownOption): void {
    this.onOpen();
    this.value.set(option.value);
    this.selectedOption.set(option.label);
  }
}
