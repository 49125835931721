<div class="text-area" [ngClass]="state()">
  <div class="text-area-container">
    <textarea class="text-area-input" [placeholder]="placeholder()" [(ngModel)]="value" [name]="name()"></textarea>
  </div>
  @if (alert()) {
    <div class="text-area-alert">
      <ui-inline-svg class="text-area-alert-icon" [name]="icon()"></ui-inline-svg>
      <span class="text-area-alert-text">{{ alert() }}</span>
    </div>
  }
</div>
