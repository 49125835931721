<div class="container">
  @for (toast of toasts; track $index) {
  <div class="toast" [ngClass]="toast.type">
    <div class="toast-message" [class.hasButton]="toast.buttonText">
      @if (toast.type === toastType.Success) {
      <ui-inline-svg class="toast-message-icon" name="check"></ui-inline-svg>
      } @else if (toast.type === toastType.Info || toast.type === toastType.Error) {
      <ui-inline-svg class="toast-message-icon" name="alert-circle"></ui-inline-svg>
      } @else {
      <ui-inline-svg class="toast-message-icon" name="alert-triangle"></ui-inline-svg>
      }

      <div class="toast-message-text">{{ toast.text }}</div>

      @if (toast.buttonText && !toast.disableClose) {
      <ui-button
        class="toast-message-button"
        [color]="$any(toast.type)"
        (click)="removeToast(toast)"
        >{{ toast.buttonText }}</ui-button
      >
      }
    </div>
    @if (!toast.disableClose) {
      <div class="toast-close">
        <ui-button-close
          [size]="'small'"
          [type]="$any(toast.type)"
          (click)="removeToast(toast)"></ui-button-close>
      </div>
    }
  </div>
  }
</div>
