<div class="postal-field" [ngClass]="state()">
  <div class="postal-field-container">
    <input class="postal-field-input" type="text" [(ngModel)]="value" [name]="name()" [placeholder]="placeholder()">
  </div>
  @if (alert() && state() !== fieldState.Default) {
    <div class="postal-field-alert">
      <ui-inline-svg class="postal-field-alert-icon" [name]="icon()"></ui-inline-svg>
      <span class="postal-field-alert-text">{{ alert() }}</span>
    </div>
  }
</div>
