<div class="bce-field" [ngClass]="state()">
  <div class="bce-field-container">
    <input class="bce-field-input" type="text" [mask]="mask()" [placeholder]="placeholder()" [(ngModel)]="value" [name]="name()">
  </div>
  @if (alert() && state() !== fieldState.Default) {
    <div class="bce-field-alert">
      <ui-inline-svg class="bce-field-alert-icon" [name]="icon()"></ui-inline-svg>
      <span class="bce-field-alert-text">{{ alert() }}</span>
    </div>
  }
</div>
